import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {

    
    odDetails: { userDetail: {}, cardDetail: {}, payment: {} },
    bookingDetails: {},
    outStandingBookingDetails: {},
    mySpotDetails:[],
    buildMode: "",
    isValidatedTimeOver: true,
    isGracePeriodTimeOver: true,
    isODExitTimeOver: false,
    loadingStatus: {},
    zoneDetails: {},
    validatedMerchant: "",
    hostPassData: {},
    pqrVerifyUsing:  'PLATE',
    templateFlags: '',
    findUserData: {},
    onDemandZoneDetails: {},
    casinoDetails: { userDetail: {}, cardDetail: {}, payment: {} },
    redeemedFreeParking:false,
    showPqrExitTimeInfo:false
  },
  mutations: {
    SET_LOADING_STATUS(state, status) {
      state.loadingStatus = { ...state.loadingStatus, [status.property]: { "pending": status.pending } }
  },
  SET_PQR_VERIFY_FLAG(state , flag) {
    state.pqrVerifyUsing = flag
  },
    SET_BOOKING_DETAILS(state, bDetails) {
      state.bookingDetails = Object.assign({}, bDetails);
    },
    SET_OUTSTANDING_BOOKING_DETAILS(state , data)
    {
      state.outStandingBookingDetails = Object.assign({} , data)
    },
    SET_TEMPLATE_FLAGS(state, data) {
      state.templateFlags = data
    },
    SET_BUILD_MODE(state, mode) {
      state.buildMode = mode;
    },
    SET_ZONE_DETAILS(state, zone) {
      state.zoneDetails = Object.assign({}, zone);
    },
    SET_MYSPOT_DETAILS(state, data) {
      state.mySpotDetails = data;
    },
    SET_ON_DEMAND_ZONE_DETAILS(state, zone) {
      state.onDemandZoneDetails = Object.assign({}, zone);
    },
    SET_VALIDATED_TIME_STATUS(state, status) {
      state.isValidatedTimeOver = status;
    },
    SET_EXIT_TIME_TIME_STATUS(state, status) {
      state.isODExitTimeOver = status;
    },
    SET_GRACE_PERIOD_TIME_STATUS(state, status) {
      state.isGracePeriodTimeOver = status;
    },
    SET_VALIDATED_MERCHANT(state, merchant) {
      state.validatedMerchant = merchant;
    },
    SET_HOSTPASS_DATA(state, data) {
      state.hostPassData = data;
    },
    SET_FINDUSER_DATA(state, data) {
      state.findUserData = data;
    },
    SET_ONDEMAND_USERDETAILS(state, data) {
      state.odDetails.userDetail = data;
    },
    SET_ONDEMAND_CARDDETAILS(state, data) {
      state.odDetails.cardDetail = data;
    },
    SET_ONDEMAND_PAYMENT(state, data) {
      state.odDetails.payment = data;
    },
    SET_CASINO_USERDETAILS(state, data) {
      state.casinoDetails.userDetail = data;
    },
    SET_CASINO_CARDDETAILS(state, data) {
      state.casinoDetails.cardDetail = data;
    },
    SET_CASINO_PAYMENT(state, data) {
      state.casinoDetails.payment = data;
    },
    SET_REDEEMED_FREE_PARKING_STATUS(state, data) {
      state.redeemedFreeParking = data;
    },
    SET_DISPLAYPQR_EXITTIME_INFO(state, data) {
      state.showPqrExitTimeInfo = data;
    },
  },
  actions: {},
  modules: {},
  getters: {
    getterOndemandExitTime: (state)=>{
      return state.isODExitTimeOver
    },
    getterPQRVerifyUsing: (state) => {
      return state.pqrVerifyUsing
    },
    getterMySpotDetails: (state)=>{
      return state.mySpotDetails;
    },
    getterBookingDetails: (state) => {
      return state.bookingDetails;
    },
    getterOutStandingBookingDetails: (state) => {
      return state.outStandingBookingDetails
    },
    getterBookingId: (state) => {
      return state.bookingDetails?.booking?.bid
        ? state.bookingDetails.booking.bid
        : null;
    },
    getterTemplateFlags: (state) => {
      return state.templateFlags;
    },
    getterEntryTime: (state) => {
      let isValidatedTimeOver = state.isValidatedTimeOver;
      if (
        state.bookingDetails?.booking?.validated &&
        state.bookingDetails.booking.validated == "1" &&
        isValidatedTimeOver &&
        state.bookingDetails?.booking?.validationType &&
        (state.bookingDetails.booking.validationType == "DIVRT" || state.bookingDetails.booking.validationType == "TICKET_TRANSFER" || state.bookingDetails.booking.validationType=="Time Reduction")
      ) {
        return (
          Number(state.bookingDetails.booking.entrytime) -
          Number(state.bookingDetails.zone.timezoneoffset) -
          Number(state.bookingDetails.zone.timezonedstoffset)
        );
      } else {
        return state.bookingDetails?.booking?.entrytime
          ? Number(state.bookingDetails.booking.entrytime) -
          Number(state.bookingDetails.zone.timezoneoffset) -
          Number(state.bookingDetails.zone.timezonedstoffset)
          : null;
      }
    },
    getterODExitTime: (state) => {
      return state.bookingDetails?.booking?.entrytime
        ? Number(state.bookingDetails.booking.entrytime) -
        Number(state.bookingDetails.zone.timezoneoffset) -
        Number(state.bookingDetails.zone.timezonedstoffset)
        : null;
    },
    getterLoadingStatus: (state) => (property) => {
      if (Object.hasOwnProperty.call(state.loadingStatus, property)) {
          return state.loadingStatus[property]["pending"]
      }
      return false;
  },
    getterHostPassData: (state) => {
      return state.hostPassData;
    },
    getterCasinoDetails: (state) => {
      return state.casinoDetails;
    },
    getterOdDetails: (state) => {
      return state.odDetails;
    },
    getterFindUserData: (state) => {
      return state.findUserData;
    },
    getterBuildMode: (state) => {
      return state.buildMode;
    },
    getterZoneDetails: (state) => {
      return state.zoneDetails;
    },
    getterOnDemandZoneDetails: (state) => {
      return state.onDemandZoneDetails;
    },
    getterEntryGateCodes: (state) => {
      return state.bookingDetails?.gateCodes.length > 0
        ? state.bookingDetails.gateCodes.filter(
          (a) => a.lane_type_current == "IN"
        )
        : [];
    },
    getterExitGateCodes: (state) => {
      return state.bookingDetails?.gateCodes.length > 0
        ? state.bookingDetails.gateCodes.filter(
          (a) => a.lane_type_current == "OUT"
        )
        : [];
    },
    getterValidatedMerchant: (state) => {
      return state.validatedMerchant;
    },
    getterZoneIsPQREndReservation : (state) =>{
      return state.zoneDetails?.isPQREndReservation == "1" ? true : false;
    },
    getterIsFreeParkingRedeemed: (state) =>{
      return state.redeemedFreeParking;
    },
    getterDisplayPqrExitTimeInfo: (state) =>{
      return state.showPqrExitTimeInfo;
    }
  },
});
