import regions from "@/utils/regions.json"
export default {
  data: () => ({
    plateRegion:{country:"", state:""},
    countries: regions.map(a => ({ name: a.name, abbreviation: a.abbreviation })),
    countryStates:[]
  }),
  methods: {
    /**
     * @method onCountryChange - Set the countryStates based onthe country selected.
     */
    onCountryChange(){
      this.countryStates = regions.find(a => a.abbreviation == this.plateRegion?.country)?.states;
      this.plateRegion = Object.assign(this.plateRegion, {state:""})
    }
  }
};