import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import './registerServiceWorker'
import vuetify from './plugins/vuetify';
import defaultDataOptions from './mixins/defaultDataOptions';
import appMixin from './mixins/app.mixin';
import plateRegion from './mixins/plateRegion.mixin';
import loggerMixin from './mixins/loggerMixin';
import PrimeVue from 'primevue/config';
import { library } from '@fortawesome/fontawesome-svg-core'
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// Prefered: as a plugin (directive + filter) + custom placeholders support
import VueMask from 'v-mask'


// import { getPerformance } from "firebase/performance";

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faHotel } from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(faHotel)

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.mixin(appMixin);
Vue.mixin(plateRegion);
Vue.use(PrimeVue);
Vue.config.productionTip = false
/** DIVRT UI Components and mixins */
Vue.mixin(defaultDataOptions);
Vue.mixin(loggerMixin);
Vue.use(VueMask);
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId:  process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
};
const app = initializeApp(firebaseConfig);
getAnalytics(app)
/** DIVRT UI Components and mixins */

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
