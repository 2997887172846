<template>
  <v-app>
    <v-main>
      <v-system-bar absolute dark color="red" v-if="buildMode == 'dev'" class="justify-space-between"><span>version : {{
        version
      }}</span><span>build : development</span></v-system-bar>
      <transition name="zoom" mode="out-in">
        <router-view></router-view>
      </transition>
    </v-main>
    <v-dialog v-model="loading" persistent>
      <v-card class="pa-4">
        <v-progress-circular indeterminate color="red"></v-progress-circular>
        <span class="ml-4" style="font-size: 14px">Please wait.</span>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import APIHelper from "./apiHelper";
import { mapGetters } from "vuex";
import { EventBus } from "@/lib/EventBus";
export default {
  name: "App",
  components: {},
  data: () => ({}),
  computed: {
    loading() {
      return (
        this.$store.getters["getterLoadingStatus"]("APP")
      );
    },
    ...mapGetters({
      buildMode: "getterBuildMode",
      bookingDetails: "getterBookingDetails",
      onDemandExitTime: "getterOndemandExitTime",

    }),
    prepaidExit() {
      return this.bookingDetails.booking?.type == 13 && this.checkReservationEndDate
    },
    checkReservationEndDate() {
      return this.bookingDetails?.booking?.revEnd &&
        new Date(
          (Number(this.bookingDetails.booking.revEnd) -
            Number(this.bookingDetails.zone.timezoneoffset) - Number(this.bookingDetails.zone.timezonedstoffset)) *
          1000
        ).getTime() -
        new Date().getTime() >
        0
        ? true
        : false;
    },
    version() {
      return process.env.VUE_APP_VERSION;
    },
  },
  mounted() {
    document.addEventListener("visibilitychange", function () {
      if (document.hidden) {
        // stop running expensive task
      } else if (window.location.hash == "#/createsession" || window.location.hash == "#/makepayment" || window.location.hash == "#/addpayment" || window.location.hash == "#/createreservation" || window.location.hash == "#/successreservation" || window.location.hash == "#/confirmreservation" || window.location.hash == "#/ondemand/odReceipt" || window.location.hash == "#/ondemand/makepayment" || window.location.hash == "#/ondemand/create" || window.location.hash == "#/ondemand" || window.location.hash == "#/prepaidhost/odReceipt" || window.location.hash == "#/prepaidhost/makepayment" || window.location.hash == "#/prepaidhost") {
        // page has focus, begin running task
      } else {
        window.location.reload();
      }
    });
    this.$store.commit("SET_LOADING_STATUS", {
      property: "APP",
      pending: false,
    });
  },
  watch: {
    $route(to) {
      if (to.fullPath == "/" && window.location.search.includes("zcode")) {
        this.$router.replace({ path: "createsession" });
      }
    },
    onDemandExitTime() {
      this.$store.commit("SET_LOADING_STATUS", {
        property: "APP",
        pending: true,
      });
      if (this.onDemandExitTime) {
        this.$store.commit("SET_LOADING_STATUS", {
        property: "APP",
        pending: true,
      });
        this.$router.replace({ path: '/checkout' })
        this.$store.commit("SET_LOADING_STATUS", {
          property: "APP",
          pending: false,
        });
      }
      this.$store.commit("SET_LOADING_STATUS", {
        property: "APP",
        pending: false,
      });
    }
  },


  async created() {

    var self = this;
    if (
      window.location.search.includes("zcode") &&
      (window.location.href.includes("createreservation"))
    ) {
      return;
    } else if (window.location.href.includes("successreservation")) {
      self.$router.replace({ path: "createreservation" });
    }
    else if (window.location.href.includes("validate")) {
      return;
    }
    else if (window.location.href.includes("confirmreservation")) {
      self.$router.replace({ path: "/createreservation" });
      return
    } else if (
      window.location.search.includes("zcode") &&
      window.location.href.includes("ondemand")
    ) {
      self.$store.commit('SET_TEMPLATE_FLAGS', 'ondemand-flag')
      // self.$router.replace({ path: "ondemand" });
      return;
    }
    // else if (
    //   window.location.search.includes("zcode") &&
    //   window.location.href.includes("prepaidhost")
    // ) {
    //   self.$store.commit('SET_TEMPLATE_FLAGS', 'atlantic-flag')
    //   self.$router.replace({ path: "prepaidhost" });
    //   return;
    // }
    else if ((window.location.search.includes("zcode") ||
      window.location.search.includes("gateid")) && window.location.href.includes('bid')) {
      return;
    }
    else if (
      window.location.search.includes("zcode") && (!window.location.href.includes('createsession') || !window.location.href.includes('ondemand') && !window.location.href.includes('createreservation'))
    ) {
      self.$router.replace({ path: "createsession" });
      return;
    }
    if (window.location.pathname.includes("/g/") || window.location.pathname.includes("/gx/")) {
      let bookingId = window.location.pathname.includes("/gx/")
        ? window.location.pathname.substring(4)
        : window.location.pathname.substring(3);
      let bookingState =
        bookingId == "/" ? null : await self.getBookingState(bookingId);
      let entryMode = this.bookingDetails?.booking?.entryMode;
      switch (bookingState) {
        case "0":
          self.$router.replace({ path: "/checkin" });
          break;
        case "2":
          if (entryMode == "OD") {
            self.$router.replace({ path: "/odExtension" });
            return;
          }
          if (this.bookingDetails?.booking?.isLPRVerified == "0" &&
            this.bookingDetails?.zone?.isGated == "0") {
            self.$router.replace({ path: "/loadsession" })
          }
          else {
            await self.$router.replace({ path: "/checkedin" });
            EventBus.$emit('GET_FREE_BOOKING')
          }
          break;
        case "8":
          if (!self.onDemandExitTime && self.bookingDetails?.zone?.ODChargeUpfront == "1" && self.bookingDetails?.booking?.TypeOfBooking == "Ondemand" && self.bookingDetails?.booking?.linkedTxnRefno == '' ) {
            await self.$router.replace({ path: '/extension' })
          }
          else if (this.prepaidExit) {
             this.reEnter(bookingId)
          }
          else if (!(window.location.href.includes('/receipt'))) {
            await self.$router.replace({ path: "/checkout" });
            if (self.bookingDetails?.booking?.autoP2PDetails) {
              EventBus.$emit('AUTO_P2P_BID', self.bookingDetails)
            }
            EventBus.$emit('SET_FREE_BOOKING')
          }
          break;
        case "5":
          self.$router.replace({ path: "/success" });
          if (self.bookingDetails?.booking?.autoP2PDetails) {
            EventBus.$emit('AUTO_P2P_BID', self.bookingDetails)
          }
          break;
        case "10":
          if (this.prepaidExit) {
             this.reEnter(bookingId);
          }
          else if (!(window.location.href.includes('/receipt'))) {
            await self.$router.replace({ path: "/checkout" });
            EventBus.$emit('SET_FREE_BOOKING')
          }
          break;
        case "11":
          if (this.prepaidExit) {
             this.reEnter(bookingId);
          }
          else if (!(window.location.href.includes('/receipt'))) {
            await self.$router.replace({ path: "/checkout" });
            EventBus.$emit('SET_FREE_BOOKING')
          }
          break;
        case "24":
          self.$router.replace({ path: "/unpaidexit" });
          break;
        case "16":
          self.$router.replace({ path: "/unpaidexit" });
          break;
        case "19":
          self.$router.replace({ path: "/promisetopay" });
          break;
        case "23":
          await self.$router.replace({ path: "/checkout" });
          EventBus.emit('AUTO_P2P_BID', self.bookingDetails)
          EventBus.$emit('SET_FREE_BOOKING')
          break;
        case "25":
          self.$router.replace({ path: "/exception" });
          break;
        case "26":
          self.$router.replace({ path: "/violation" });
          break;
        case "-99":
          self.$router.replace({ path: "/expired" });
          break;
        case "99":
          self.$router.replace({ path: "/cancelled" });
          break;
        case null:
          self.$router.replace({ path: "/invalidbid" });
          break;
      }
    } else {
      this.$router.replace({ path: "page-not-found" })
    }
    this.$store.commit("SET_LOADING_STATUS", {
      property: "APP",
      pending: false,
    });
  },
  methods: {
    async reEnter(bookingId) {
      if (bookingId != null) {
        try {
          var self = this;
          var bDetails = await APIHelper(
            "GET",
            "/api/v1/booking/config/" + bookingId + "?reEnter=1"
          );
          this.$store.commit(
            "SET_BOOKING_DETAILS",
            bDetails.data?.data ? bDetails.data.data : null
          );
          let bookingState = bDetails.data?.data?.booking?.state
          if ((bookingState == 8 || bookingState == 10 || bookingState == 11) && (!(window.location.href.includes('/receipt')))) {
            await this.$router.replace({ path: "/checkout" });
            EventBus.$emit('SET_FREE_BOOKING')
          } else if (bookingState == 2) {
            await this.$router.replace({ path: "/checkedin" });
            EventBus.$emit('GET_FREE_BOOKING')
          } else if (bookingState == 0) {
            this.$router.replace({ path: "/checkin" });
          } else {
            setTimeout(async () => {
              await self.reEnter();
            }, 4000);
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
    async getBookingState(bookingId) {
      try {
        this.$store.commit("SET_LOADING_STATUS", {
      property: "APP",
      pending: true,
    });
        var bookingDetails = await APIHelper(
          "GET",
          "/api/v1/booking/config/" + bookingId
        );
        // bookingDetails.data.serverName == 'dev' ? document.querySelector('meta[name="theme-color"]').setAttribute("content", "#43A047") : "";
        this.$store.commit(
          "SET_BOOKING_DETAILS",
          bookingDetails.data?.data ? bookingDetails.data.data : null
        );
        this.$store.commit(
          "SET_BUILD_MODE",
          bookingDetails.data?.serverName
            ? bookingDetails.data.serverName
            : null
        );
        // These stored details are useful to check if there is an active session which is used in digital validation flow.
        localStorage.setItem(
          "bookingDetails",
          JSON.stringify({
            zcode: bookingDetails.data?.data?.zone?.zcode,
            bid: bookingDetails.data?.data?.booking?.bid,
          })
        );
        this.$store.commit("SET_LOADING_STATUS", {
      property: "APP",
      pending: false,
    });
        return bookingDetails.data?.data?.booking?.state
          ? bookingDetails.data.data.booking.state
          : null;
      } catch (error) {
        this.$store.commit("SET_LOADING_STATUS", {
      property: "APP",
      pending: false,
    });
        console.log(error);
      }
    },
  },
};
</script>
<style lang="less">
.v-application {
  background-image: url("assets/dashboad_header_bg.png") !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
  font-family: "Work Sans" !important;
}

input {
  font-family: "Work Sans" !important;
}

@media (min-width: 320px) {
  .v-tooltip__content {
    width: 270px !important;
    background-color: #3b3b3b !important;
    opacity: 1 !important;
    margin-left: 12.3px;
  }
}

@media (min-width: 360px) {
  .v-tooltip__content {
    width: 320px !important;
    background-color: #3b3b3b !important;
    opacity: 1 !important;
    margin-left: 7.5px !important;
  }
}

@media (min-width: 375px) {
  .v-tooltip__content {
    width: 340px !important;
    background-color: #3b3b3b !important;
    opacity: 1 !important;
    margin-left: 5px !important;
  }
}

@media (min-width: 384px) {
  .v-tooltip__content {
    width: 345px !important;
    background-color: #3b3b3b !important;
    opacity: 1 !important;
    margin-left: 7.5px !important;
  }
}



@media (min-width: 390px) {
  .v-tooltip__content {
    width: 350px !important;
    background-color: #3b3b3b !important;
    opacity: 1 !important;
    margin-left: 9px !important;
  }
}

@media (min-width: 411px) {
  .v-tooltip__content {
    width: 350px !important;
    background-color: #3b3b3b !important;
    opacity: 1 !important;
    margin-left: 1.2rem !important;
  }
}

@media (min-width: 414px) {
  .v-tooltip__content {
    width: 350px !important;
    background-color: #3b3b3b !important;
    opacity: 1 !important;
    margin-left: 1.2rem !important;
  }
}

@media (min-width: 768px) {
  .v-tooltip__content {
    width: 350px !important;
    background-color: #3b3b3b !important;
    opacity: 1 !important;
    margin-left: 2.8rem !important;
  }
}

@media (min-width: 810px) {
  .v-tooltip__content {
    width: 350px !important;
    background-color: #3b3b3b !important;
    opacity: 1 !important;
    margin-left: 2.8rem !important;
  }
}

@media (min-width: 820px) {
  .v-tooltip__content {
    width: 350px !important;
    background-color: #3b3b3b !important;
    opacity: 1 !important;
    margin-left: 2.9rem !important;
  }
}


.v-input input {
  max-height: none !important;
}

.fit-height {
  height: 100%;
}

.fit-width {
  width: 100%;
}

.color-black {
  color: #44515a;
}

.page-enter-active,
.page-leave-active {
  transition: opacity 1s, transform 1s;
}

.page-enter,
.page-leave-to {
  opacity: 0;
  transform: translateX(-30%);
}

.zoom-enter-active,
.zoom-leave-active {
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-name: zoom;
}

.zoom-leave-active {
  animation-direction: reverse;
}

.exit_dialog_btn {
  width: 100%;
  background-color: #f2555c !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  letter-spacing: normal !important;
  height: 50px !important;
}

@keyframes zoom {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  100% {
    opacity: 1;
  }
}
.plate-region-label{
font-size: 14px !important;
}
.plate-region-label label{
  font-size:14px !important;
}
.plate-region-label .v-select__selections{
  flex-wrap: nowrap !important;
  padding-left:8px !important;
}
.theme--light.plate-region-label.v-text-field.v-input--is-disabled .v-input__slot::before{
  border-image: inherit !important;
}
.v-list-item--link{
  border-bottom: 1px solid rgba(0, 0, 0, .12) !important;
}
.country-name{
  align-self: center;
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

</style>