import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
const originalReplace = VueRouter.prototype.replace;
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err);
};
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: '/ondemand/create',
    component: () => import('../views/OndemandOTP.vue')
  },
  {
    path: '/ondemand/makepayment',
    component: () => import('../views/makePayment.vue')
  },
  {
    path: '/prepaidhost/makepayment',
    component: () => import('../views/makePayment.vue')
  },
  {
    path: '/odExtension',
    component: () => import('../views/OnDemand/ODVExtension.vue')
  },
  {
    path: '/extension',
    component: () => import('../views/OnDemand/ChargeODExtension.vue')
  },
  {
    path: '/ondemand/odReceipt',
    component: () => import('../views/odReceipt.vue')
  },
  {
    path: '/prepaidhost/odReceipt',
    component: () => import('../views/odReceipt.vue')
  },
  {
    path: '/thankyouloading',
    component: () => import('../uiComponents/ThankYouLoading.vue')
  },
  {
    path: "/ondemand",
    component: () => import("../views/OnDemand/ODTemplatesView.vue")
  },
  // {
  //   path: "/prepaidhost",
  //   component: () => import("../views/OdTemplates.vue")
  // },
  {
    path: "/:pathMatch(/g/)*", //  "/g/:bid", //:bid*
    name: "",
    component: () => import("../views/Test.vue"),
    children: [
      {
        name: 'odMakePayment',
        path: '/makepayment',
        component: () => import('../views/OnDemand/ChargeUpFront.vue')
      },
      {
        path: "/loadsession",
        name: "LoadSession",
        component: () => import("../views/LoadSession.vue"),
      },
      {
        path: '/promisetopay',
        name: "PromiseToPay",
        component: () => import('../views/P2PPaymentView.vue')
      },
      {
        path: "/checkin",
        name: "CheckIn",
        component: () => import("../views/CheckIn.vue"),
      },
      {
        path: "/checkedin",
        name: "CheckedIn",
        component: () => import("../views/CheckedIn.vue"),
      },
      {
        path: "/exception",
        name: "Exception",
        component: () => import("../views/Exception.vue"),
      },
      {
        path: "/violation",
        name: "Violation",
        component: () => import("../views/Violation.vue"),
      },
      {
        path: "/expired",
        name: "Expired",
        component: () => import("../views/ExpiredBooking.vue"),
      },
      {
        path: "/cancelled",
        name: "Cancelled",
        component: () => import("../views/CancelledBooking.vue"),
      },
      {
        path: "/checkout",
        name: "ThankYou",
        component: () => import("../views/ThankYou.vue"),
      },
      {
        path: "/unpaidexit",
        name: "UnpaidExit",
        component: () => import("../views/UnpaidExit.vue"),
      },
      {
        path: "/checkinsuccess",
        name: "CheckInSuccess",
        component: () => import("../views/CheckInSuccessCheck.vue"),
      },
      {
        path: "/validatesuccess",
        name: "Validate",
        component: () => import("../views/SuccessValidateBooking.vue"),
      },
      {
        path: "/pedestrianaccess",
        name: "PedestrianAccess",
        component: () => import("../views/SuccessPedestrianAccess.vue"),
      },
      {
        path: "/addpayment",
        name: "AddPayment",
        component: () => import("../views/AddPaymentHPS.vue"),
      },
      {
        path: "/invalidbid",
        name: "InvalidBooking",
        component: () => import("../views/InvalidBooking.vue"),
      },
      {
        path: "/receipt",
        name: "Receipt",
        component: () => import("../views/Receipt.vue"),
      },
      {
        path: "/success",
        name: "Success",
        component: () => import("../views/SuccessCheck.vue"),
      },
    ],
  },
  {
    path: "/gx/:bid",
    name: "",
    component: () => import("../views/Test.vue"),
    children: [
      {
        path: '/makepayment',
        name: "GXMakePayment",
        component: () => import('../views/OnDemand/ChargeUpFront.vue')
      },
      {
        path: "/loadsession",
        name: "GXLoadSession",
        component: () => import("../views/LoadSession.vue"),
      },
      {
        path: "/checkin",
        name: "GXCheckIn",
        component: () => import("../views/CheckIn.vue"),
      },
      {
        path: "/checkedin",
        name: "GXCheckedIn",
        component: () => import("../views/CheckedIn.vue"),
      },
      {
        path: "/expired",
        name: "GXExpired",
        component: () => import("../views/ExpiredBooking.vue"),
      },
      {
        path: "/cancelled",
        name: "GXCancelled",
        component: () => import("../views/CancelledBooking.vue"),
      },
      {
        path: "/checkout",
        name: "GXThankYou",
        component: () => import("../views/ThankYou.vue"),
      },
      {
        path: "/checkinsuccess",
        name: "GXCheckInSuccess",
        component: () => import("../views/CheckInSuccessCheck.vue"),
      },
      {
        path: "/validatesuccess",
        name: "GXValidate",
        component: () => import("../views/SuccessValidateBooking.vue"),
      },
      {
        path: "/pedestrianaccess",
        name: "GXPedestrianAccess",
        component: () => import("../views/SuccessPedestrianAccess.vue"),
      },
      {
        path: "/addpayment",
        name: "GXAddPayment",
        component: () => import("../views/AddPaymentHPS.vue"),
      },
      {
        path: "/invalidbid",
        name: "GXInvalidBooking",
        component: () => import("../views/InvalidBooking.vue"),
      },
      {
        path: "/receipt",
        name: "GXReceipt",
        component: () => import("../views/Receipt.vue"),
      },
      {
        path: "/success",
        name: "GXSuccess",
        component: () => import("../views/SuccessCheck.vue"),
      },
    ],
  },
  {
    path: "/validate",
    name: "ValidateSession",
    component: () => import("../views/ValidateSession.vue"),
  },
  {
    path: "/validatefailure",
    name: "ValidationFailure",
    component: () => import("../views/ValidationFailure.vue"),
  },
  {
    path: "/createsession",
    name: "CreateSession",
    component: () => import("../views/Create.vue"),
  },
  {
    path: "/createreservation",
    name: "CreateHostpass",
    component: () => import("../views/FindUser.vue"),
  },
  {
    path: "/confirmreservation",
    name: "ConfirmReservation",
    component: () => import("../views/ConfirmHostPass.vue"),
  },
  {
    path: "/successreservation",
    name: "SuccessHostPass",
    component: () => import("../views/SuccessHostPass.vue"),
    props: true,
  },
  {
    path: "/:pathMatch(.*)*",
    // path: "/:catchAll(.*)",
    component: () => import("../views/PageNotFound.vue"),
  },
  {
    path: "/page-not-found",
    component: () => import("../views/PageNotFound.vue"),
  },
];

const router = new VueRouter({
  routes,
  scrollBehavior() {
    document.getElementById("app").scrollIntoView();
  },
  // mode:"history"
});


router.beforeEach((to, from, next) => {
  // Assuming you have an API call or some asynchronous logic
  // before displaying the 404 page
  // You can add your API call logic here

  // For demonstration purposes, let's simulate an API call with a timeout
  setTimeout(() => {
    // If the route doesn't match any defined routes, navigate to the 404 page
    if (to.matched.length === 0) {
      next({ path: '/404' });
    } else {
      // Continue with the navigation
      next();
    }
  }, 1000);});
export default router;
